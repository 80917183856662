<template>
	<div class="messages">
		<div class="comment-respond" id="respond">
			<h4>留下你的评论吧</h4><div class="help-tip" v-if="image"><p>该评论区为留言板模块<br /></p></div>
			<el-button type="primary" @click="cancel()" class="cancel-reply" v-show="comment.parentCmtId!=-1">取消评论</el-button>
			<el-form @submit.native.prevent :model="comment" :rules="rules" ref="comment" :label-position="labelPosition" label-width="100px" class="demo-ruleForm">
				<el-form-item  label="Comment" prop="content" class="comment-content" id="comment-content-id">
				    <el-input type="textarea" :class="comment.privateCmt?'private':''" v-model="comment.content" @change='change(0,)' placeholder="说点什么吧 o(〃＾▽＾〃)o" id="comment-textarea-id"></el-input>
				</el-form-item>
				<template>
					<twemoji-picker
						ref="twemoji"
					    :emojiData="emojiDataAll"
					    :emojiGroups="emojiGroups"
						:pickerWidth="'#comment-content-id'"
						@emojiUnicodeAdded='addEmojiCode'
					    searchEmojiPlaceholder="Search here."
					    searchEmojiNotFound="Emojis not found."
					    isLoadingLabel="Loading..."
						pickerPlacement='bottom-start'
						id='emoji'
					  ></twemoji-picker>
				</template>
				<div class="comment-info">
					<el-form-item label="Name" prop="nickname" class="comment-el">
					    <el-input v-model="comment.nickname" :class="comment.privateCmt?'private':''"  placeholder="Name or nickname" @input='checkN()'></el-input>
					</el-form-item>
					<el-form-item label="Email" prop="email" class="comment-el">
					    <el-input v-model="comment.email" :class="comment.privateCmt?'private':''" placeholder="E-mail"></el-input>
					</el-form-item>
					<el-form-item label="Site" prop="site" class="comment-el">
					    <el-input v-model="comment.site" :class="comment.privateCmt?'private':''" placeholder="Website or blog"></el-input>
					</el-form-item>
				</div>
				<el-form-item>
				    <el-button type="primary" @click="submitForm('comment')" class="leave-btn" :disabled='!allowComment'>Leave a Comment</el-button>
					<div class="privateCmt">
						<label>私密评论</label>
						<el-switch v-model="comment.privateCmt" active-color="#3ebce2" inactive-color="#ffffff"></el-switch>
					</div>
					<input type="hidden" v-model="comment.aid" name="aid" value="-1">
					<input type="hidden" v-model="comment.parentCmtId" name="parentCmtId" value="-1">
				</el-form-item>
			</el-form>
		</div>
		<div id="comment-form-place-holder"></div>
		<h4 v-text="'已有'+msgs.length+'条评论'"></h4>
		<ol class="comments-list">
			<li v-for="(msg,index) in msgs" :key='index' :id="isArticle?'comment-'+msg.cid:'comment-'+msg.mid">
				<div class="comments-body">
					<a :href="msg.site!=null&&msg.site!=''&&msg.site!=-1?msg.site:'javascript:;'"><img v-lazy="msg.avatar==null?'/img/kasuie/avatar_default.png':msg.avatar" ></a>
					<div class="comments-content">
						<div class="comments-meta">
							<span class="comments-name" v-text="msg.nickname"></span><i v-if="msg.adminMsg||msg.adminCmt">博主</i>
							<div class="comments-time">
								<time :datetime="msg.createTime" v-text="$moment(msg.createTime).format('YYYY-MM-DD hh:mm:ss a dddd')"></time>
							</div>
						</div>
						<div class="comments-text" v-if="isArticle?msg.site!=-1&&(msg.privateCmt==false||msg.uid==user.uid||user.level>1):msg.site!=-1&&(msg.privateMsg==false||msg.uid==user.uid||user.level>1)">
							{{msg.content}}
						</div>
						<div class="comments-text" v-if="isArticle?msg.site!=-1&&msg.privateCmt==true&&msg.uid!=user.uid&&user.level==1:msg.site!=-1&&msg.privateMsg==true&&msg.uid!=user.uid&&user.level==1">
							<img src="/img/kasuie/private.png" >该评论仅评论双方可见
						</div>
						<div class="comments-text deleted" v-if="msg.site!=null&&msg.site==-1">
							这条评论已被删除了(ಥ _ ಥ)
						</div>
						<div class="comments-reply" v-if="msg.site!=-1">
							<el-button type="primary" @click="typeReply(isArticle?'comment-'+msg.cid:'comment-'+msg.mid,isArticle?msg.cid:msg.mid)" round  class="reply-btn">Reply</el-button>
							<el-popconfirm
							  confirm-button-text='确认'
							  cancel-button-text='取消'
							  icon="el-icon-info"
							  icon-color="red"
							  title="确定删除该评论吗？(ಥ _ ಥ)"
							  @confirm="delReply(isArticle?msg.cid:msg.mid)"
							  @cancel="cancelDel()"
							>
								<el-button type="danger" slot="reference" round class="delete-btn" v-if="admin||msg.uid==user.uid">Delete</el-button>
							</el-popconfirm>
						</div>	
					</div>
				</div>
				<div class="comments-children">
					<ol>
						<li v-for="(msgc,index) in isArticle?msg.replyComments:msg.replyMessages" :key='index' :id="isArticle?'comment-'+msgc.cid:'comment-'+msgc.mid">
							<div class="comments-body">
								<a :href="msgc.site!=null&&msgc.site!=''&&msgc.site!=-1?msgc.site:'javascript:;'"><img v-lazy="msgc.avatar==null?'/img/kasuie/avatar_default.png':msgc.avatar" ></a>
								<div class="comments-content">
									<div class="comments-meta">
										<span class="comments-name" v-text="msgc.nickname"></span><i v-if="msgc.adminMsg||msgc.adminCmt">博主</i>
										<div class="comments-time">
											<time :datetime="msgc.createTime" v-text="$moment(msgc.createTime).format('YYYY-MM-DD hh:mm:ss a dddd')"></time>
										</div>
									</div>
									<div class="comments-text" v-if="isArticle?msgc.site!=-1&&(msgc.privateCmt==false||msgc.uid==user.uid):msgc.site!=-1&&(msgc.privateMsg==false||msgc.uid==user.uid||msg.uid==user.uid||user.level>1)">
										<span>{{isArticle?'@'+msgc.parentName:'@'+msgc.parentNickname}}</span> {{msgc.content}}
									</div>
									<div class="comments-text" v-if="isArticle?msgc.site!=-1&&msgc.privateCmt==true&&msgc.uid!=user.uid:msgc.site!=-1&&msgc.privateMsg==true&&msgc.uid!=user.uid&&user.level==1">
										<img src="/img/kasuie/private.png" >该评论仅评论双方可见
									</div>
									<div class="comments-text deleted" v-if="msgc.site!=null&&msgc.site==-1">
										这条评论已被删除了(ಥ _ ಥ)
									</div>
									<div class="comments-reply" v-if="msgc.site!=-1">
										<el-button type="primary" @click="typeReply(isArticle?'comment-'+msgc.cid:'comment-'+msgc.mid,isArticle?msgc.cid:msgc.mid)" round  class="reply-btn">Reply</el-button>
										<el-popconfirm
										  confirm-button-text='确认'
										  cancel-button-text='取消'
										  icon="el-icon-info"
										  icon-color="red"
										  title="确定删除该评论吗？(ಥ _ ಥ)"
										  @confirm="delReply(isArticle?msgc.cid:msgc.mid)"
										  @cancel="cancelDel()"
										>
											<el-button type="danger" slot="reference" round class="delete-btn" v-if="admin||msgc.uid==user.uid">Delete</el-button>
										</el-popconfirm>
									</div>	
								</div>
							</div>
						</li>
					</ol>
				</div>
			</li>
		</ol>
	</div>
</template>

<script>
import { TwemojiPicker } from '@kevinfaguiar/vue-twemoji-picker';
import EmojiAllData from '@kevinfaguiar/vue-twemoji-picker/emoji-data/zh/emoji-all-groups.json';
import EmojiGroups from '@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json';

export default {
	name: 'MessageC',
	components: {
		'twemoji-picker': TwemojiPicker
	},
    data() {
		var validateName = (rule, value, callback) => {
			if (value != ''){
				if(!this.checkName&&!this.login){
					callback(new Error('用户名已存在'));
				}
				callback();
			}else{
				callback(new Error('请输入昵称'));
			}
		};
		var validateEmail = (rule,value,callback) => {
			if(value != ''){
				let rgx = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/
				if(value.match(rgx)==null){
					callback(new Error('请输入正确的邮箱'));
				}
				callback();
			}else{
				callback(new Error('请输入邮箱'));
			}
		}
      return {
		msgs: [],
		commentId: null,
		labelPosition: 'top',
		isArticle: false, 
		admin: false,
		allowComment: true,
		checkName: true,
		login: localStorage.getItem("login"),
		loading: true,
		comment: {
			nickname: '',
			uid: '-1',
			email: '',
			site: '',
			content: '',
			privateCmt: false,
			parentCmtId: '-1',
			parentMsgId: '-1',
			parentName: '',
			parentNickname: '',
			aid: '-1',
		},
		user: {
			uid: '',
			username: '',
			email: '',
			level: 1,
		},
		rules: {
		    nickname: [
				{ required: true, message: '请输入昵称', trigger: 'blur' },
				{ validator: validateName, trigger: 'blur' },
				{ min: 3, max: 15, message: '长度在 3 到 15 个字符', trigger: 'blur' },
			],
			email: [
				{ required: true, message: '请输入邮箱', trigger: 'change' },
				{ validator: validateEmail, trigger: 'blur' },
			],
			content: [
				{ required: true, message: '请输入评论内容', trigger: 'change' }
			],
		}
      };
    },
	props:['messages','aid','commentable','image'],
	methods: {
		checkN(){
			let that = this
			if(that.comment.nickname!=null&&that.comment.nickname!=''){
				let url = 'checkName?username='+that.comment.nickname
				this.axios.get(url).then(function(results){
					that.checkName = results.data
				},function(err){
					console.log(err)
				})
			}
		},
		change(num,name){
			let textarea = document.getElementById('comment-textarea-id')
			if(num==0){
				textarea.setAttribute('placeholder','居然什么也不说,哼 o((>ω< ))o')
			}else{
				textarea.setAttribute('placeholder','@'+name)
			}
		},
		submit(){
			let that = this
			that.comment.parentMsgId = that.comment.parentCmtId
			if(that.comment.nickname!=''&&that.comment.content!=''&&that.comment.site!=-1){
				this.axios.post('saveComment',
					that.comment
				).then(function(results){
					if(results.data){
						that.login?'':that.$cookies.set("commented", that.comment.nickname+','+that.comment.email+','+that.comment.site , "1y")
						that.refresh()
					}else{
						this.$notify.error({
						    title: '错误',
						    message: '评论失败'
						});
					}					
				},function(err){
					console.log(err)
				})
			}else{
				this.$notify.error({
				    title: '错误',
				    message: '评论错误'
				});
			}
		},
		submitForm(formName) {
		    this.$refs[formName].validate((valid) => {
				if (valid) {
					// this.replaceEmoji(this.comment.content)
					this.submit()
				} else {
		            return false;
		        }
		    });
		},
		resetForm(formName) {
		    this.$refs[formName].resetFields();
		},
		dom(id){
			return document.getElementById(id)
		},
		typeReply(id, mid){
			this.comment.parentCmtId = mid
			let respond = this.dom('respond')
			let comment = this.dom(id)
			let parentName = comment.getElementsByClassName('comments-name')[0].innerText;
			this.change(1,parentName)
			comment.append(respond)
		},
		delReply(id){
			let that = this
			let url = that.isArticle?'deleteCmt':'deleteMsg'
			this.axios.post(url,id).then(function(results){
				if(results.data){
					that.$notify({
						title: '成功',
						message: '删除成功',
						type: 'success'
					});
					that.$emit('getMsg')
				}else{
					that.$notify.error({
					    title: '错误',
					    message: '删除失败'
					});
				}
			},function(err){
				that.$notify.error({
				    title: '错误',
				    message: '删除失败'
				});
				console.log(err);
			})
		},
		cancelDel(){
			this.$message({
				type: 'info',
				message: '取消删除'
			});
		},
		cancel(){
			let holder = this.dom('comment-form-place-holder')
			let respond = this.dom('respond')
			this.comment.parentCmtId=-1
			this.change(0,)
			holder.parentNode.insertBefore(respond, holder)
		},
		notify(){
			this.$notify({
				title: '成功',
				message: '评论成功',
				type: 'success'
			});
		},
		refresh(){
			this.notify()
			this.resetForm('comment')
			this.$emit('getMsg')
			this.cancel()
		},
		addEmojiImg(emojiImgTag){
			// let textarea = document.getElementById('comment-textarea-id')
			// textarea.innerHTML = textarea.innerHTML + emojiImgTag
			console.log('emojiImgTag:'+emojiImgTag)
		},
		addEmojiCode(emojiUnicode){
			// let textarea = document.getElementById('comment-textarea-id')
			// textarea.innerHTML = textarea.innerHTML + emojiUnicode
			// this.comment.content = this.comment.content +emojiUnicode
			// this.comment.content = this.comment.content + emojiUnicode
			// console.log(emojiUnicode)
			this.insertEmoji(emojiUnicode)
		},
		async insertEmoji(emoji){
			const textarea = document.getElementById('comment-textarea-id')
			if(textarea.selectionStart || textarea.selectionStart === 0){
				let startPos = textarea.selectionStart
				let endPos = textarea.selectionEnd
				this.comment.content = this.comment.content.substring(0,startPos) + emoji + this.comment.content.substring(endPos,this.comment.content.length)
				await this.$nextTick()
				textarea.focus()
				textarea.setSelectionRange(endPos + emoji.length, endPos + emoji.length)
			}else{
				this.comment.content = emoji
			}
		}
	},
	computed: {
		emojiDataAll() {
			return EmojiAllData;
	    },
	    emojiGroups() {
			return EmojiGroups;
	    }
	},
	watch: {
		messages(){
			this.msgs =  this.messages
			this.loading = false
		},
		aid(){
			this.isArticle = true
			this.comment.aid = this.aid
		},
		commentable(){
			this.allowComment = this.commentable
		}
	},
	mounted(){
		if(this.login){
			this.user = this.$store.getters.getUser
			this.login = this.$store.getters.getUser
			this.comment.nickname = this.user.username
			this.comment.email = this.user.email
			this.comment.uid = this.user.uid
			this.comment.avatar = this.user.avatar
			if (this.user.level>2) {
				this.admin = true
			}
		}else if(this.$cookies.isKey("commented")){
			let commented = this.$cookies.get("commented").split(',')
			this.comment.nickname = commented[0]
			this.comment.email = commented[1]
			this.comment.site = commented[2]
		}
	}
}
</script>

<style>
.messages{
	width: 55%;
	color: var(--theme-color);
	padding: 2em;
	margin: 0 auto;
	background-color: rgba(255,255,255,.3);
}
#emoji-popover-header .active{
	display: inline-block;
}
#emoji{
	display: inline-block;
}
.messages h4{
	text-align: start;
}
.comments-list{
	list-style: none;
}
.comments-list li{
	border-top: 1px solid #adadad;
	padding-top: .7em;
	margin-top: .5em;
}
.comments-body a img{
	width: 3em;
	height: 3em;
	float: left;
	border-radius: 50%;
}
.comments-content{
	text-align: start;
	margin-left: 4em;
	margin-bottom: 1em;
}
.comments-text{
	color: #2c3e50;
}
.comments-text span{
	cursor: pointer;
	color: #2296b9;
}
.deleted{
	color: grey;
	text-decoration: line-through;
}
.comments-meta span{
	font-weight: 700;
}
.comments-meta i{
	font-size: .7em;
	background: #0e0e0e;
	color: white;
	margin-left: .2em;
	padding: .1em .5em;
	border-radius: .3em;
}
.comments-content .comments-text,.comments-reply{
	margin-top: .7em;
}
.comments-time{
	margin-top: .7em;
	font-size: 80%;
	color: grey;
}
.comments-reply .reply-btn,.comments-reply .delete-btn,.cancel-reply{
	font-size: 75%;
	padding: .3em .8em;
	background-color: #4ea3bd;
}
.comments-reply .el-button.is-round{
	border-radius: 7px;
}
.comments-reply .delete-btn{
	margin-left: 10px;
	background-color: #ef5656;
}
.comments-reply .delete-btn:hover{
	background-color: #ff3d3d;
}
.cancel-reply{
	background-color: #ff758a !important;
	border-color: #ef6076 !important;
}
.comment-respond{
	text-align: left;
}
.comment-respond h4{
	display: inline-block;
	margin-right: .5em;
}
.comment-content{
	margin-bottom: 0;
}
.comments-children li{
	margin-left: 4em;
	list-style: none;
}
.el-textarea__inner{
	min-height: 120px !important;
}
.el-form--label-top .el-form-item__label{
	padding: unset;
}
.comment-el{
	width: 30%;
	display: inline-block;
}
.el-input{
	width: 85%;
}
.leave-btn{
	padding: 12px 32px;
}
.private input,.private textarea{
	color: white;
	background-color: #000000;
	border: 1px solid #7064a1;
}
.privateCmt{
	display: inline-block;
	margin: 0 2em 0 .5em;
	height: 0;
	position: relative;
}
.privateCmt label{
	position: relative;
	bottom: -.7em;
}
.el-switch{
	vertical-align: bottom;
}
.el-switch__core{
	height: 18px;
	width: 35px !important;
}
.el-switch__core:after{
	width: 13px;
	height: 13px;
	border: 1px solid grey;
}
.el-button--primary{
	background-color: #3ebce2;
	border-color: #33b5dc;
}
.el-button--primary:hover{
	background-color: #30a2d8;
}
.comments-text img{
	vertical-align: middle;
}
#respond>span{
	color: purple;
	font-size: .85em;
}
/* The icon */
.help-tip{
	transform: translate(-50%, 0%);
	margin: auto;
	margin-left: .5em;
	text-align: center;
	border: 2px solid red;
	border-radius: 50%;
	width: 1em;
	height: 1em;
	font-size: 12px;
	line-height: 12px;
	cursor: pointer;
	display: inline-block;
}
.help-tip:before{
    content:'!';
    font-family: sans-serif;
    font-weight: normal;
    color: red;
}
.help-tip:hover p{
    display:block;
    transform-origin: 100% 0%;
    -webkit-animation: fadeIn 0.3s ease;
    animation: fadeIn 0.3s ease;
}
.help-tip p {    
	display: none;
	font-family: sans-serif;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	text-align: center;
	background-color: var(--theme-background-color);
	padding: 12px 16px;
	width: 178px;
	height: auto;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 5%);
	border-radius: 3px;
/* 	border: 1px solid #E0E0E0; */
	box-shadow: 0 0px 20px 0 rgba(0,0,0,0.1);
	color: var(--theme-color);
	font-size: 12px;
	line-height: 18px;
	z-index: 99;
}
.help-tip p a {
	color: #067df7;
	text-decoration: none;
}
.help-tip p a:hover {
	text-decoration: underline;
}
.help-tip p:before { 
	position: absolute;
	content: '';
	width: 0;
	height: 0;
	border: 10px solid transparent;
	border-bottom-color: var(--theme-background-color);
	top: -9px;
	left: 50%;
	transform: translate(-50%, -50%);
}
 /* Prevents the tooltip from being hidden */
.help-tip p:after {
	width: 10px;
	height: 40px;
	content:'';
	position: absolute;
	top: -40px;
	left: 0;
}
/* CSS animation */
@-webkit-keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:100%; }
}

@keyframes fadeIn {
    0% { opacity:0; }
    100% { opacity:100%; }
}
.darkApp .comments-meta i{
	background: purple;
}
.darkApp #respond>span{
	color: #ffafa9;
}
.darkApp .comments-text{
	color: #d6d6d6;
}
.darkApp .deleted{
	color: grey;
}
.darkApp .comments-list li{
	border-top: 1px solid #424242;
}
.darkApp .el-form-item__label{
	color: #ababab;
}
.darkApp .messages{
	background-color: var(--theme-background-color)
}
.darkApp .el-textarea__inner,.darkApp .el-input__inner{
	color: #d6d6d6;
	border: 1px solid #3c3c3c;
	background-color: black;
}
@media screen and (max-width: 1400px) {
	.messages{
		width: 65%;
	}
}
@media screen and (max-width: 770px) {
	.messages{
		width: 90%;
		padding: 2em 1em;
	}
	.comment-el{
		width: 100%;
	}
	.comment-info>.el-form-item{
		margin-bottom: 0;
	}
	.comment-info>div.el-form-item:nth-last-child(1){
		margin-bottom: 22px;
	}
	.el-input{
		width: 100%;
	}
}
</style>
